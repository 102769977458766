import type { ReactNode } from 'react';

import { Group, Text, ThemeIcon } from '@mantine/core';

import { ListItemButtonLink, ListItemButton, type ListItemButtonLinkProps, type ListItemButtonProps } from './ListItemButton';

export interface SecondaryTextProps {
  text: ReactNode;
  icon?: ReactNode;
}
export const SecondaryText = (props: SecondaryTextProps) => {
  const { icon, text } = props;

  if (!icon) {
    return (
      <Text component='span' c='dimmed' size='sm'>
        {text}
      </Text>
    );
  }

  return (
    <Group gap={2} align='center'>
      <ThemeIcon variant='transparent' c='dimmed' size='sm'>
        {icon}
      </ThemeIcon>
      <Text component='span' c='dimmed' size='sm'>
        {text}
      </Text>
    </Group>
  );
};

export interface ForkliftListItemContentProps {
  vehicleId: string | number;
  label: string;
  secondaryText: ReactNode;
}

export const ForkliftListItemContent = (props: ForkliftListItemContentProps): JSX.Element => {
  const { vehicleId, label, secondaryText } = props;
  return (
    <>
      <Group gap={8}>
        <Text fw='bold' component='span'>
          #{vehicleId}
        </Text>
        <Text component='span'>{label}</Text>
      </Group>
      <Group gap={8}>{secondaryText}</Group>
    </>
  );
};

export interface ForkliftListItemLinkProps extends ForkliftListItemContentProps, Omit<ListItemButtonLinkProps, 'children'> {}

export const ForkliftListItemLink = (props: ForkliftListItemLinkProps): JSX.Element => {
  const { vehicleId, label, secondaryText, ...btnProps } = props;
  return (
    <ListItemButtonLink {...btnProps}>
      <ForkliftListItemContent vehicleId={vehicleId} label={label} secondaryText={secondaryText} />
    </ListItemButtonLink>
  );
};

export interface ForkliftListItemProps extends ForkliftListItemContentProps, Omit<ListItemButtonProps, 'children'> {}

export const ForkliftListItem = (props: ForkliftListItemProps): JSX.Element => {
  const { vehicleId, label, secondaryText, ...btnProps } = props;
  return (
    <ListItemButton {...btnProps}>
      <ForkliftListItemContent vehicleId={vehicleId} label={label} secondaryText={secondaryText} />
    </ListItemButton>
  );
};
